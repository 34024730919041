import React, { Component } from "react";
import './App.css';
import { Container, Button } from 'react-bootstrap'
import { withRouter } from "react-router-dom";
import Footer from './components/Footer'
import Routes from "./Routes";
import { Link } from 'react-router-dom'
import logohw from './img/logos/logo-top-white.png';
import logohg from './img/logos/logo-top-green.png';
import { FaGlobeAmericas } from 'react-icons/fa';
import { MdOutlineClose } from 'react-icons/md';
import { API } from "aws-amplify";
import ScrollRestoration from 'react-scroll-restoration';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideMenuActive: false,
      pageYOffset: 0,
      isDarkMode: false,
      version: ""
    };
  }
  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
    this.useDarkMode();
  }
  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
    const cleanup = this.useDarkMode();
    if (cleanup) cleanup();
  }
  handleScroll = (e) => {
    this.setState({ pageYOffset: window.pageYOffset });
  }
  getVersion = async () => {
    let res = await API.post('data', 'getdata', {
      body: { action: 'version' }
    }).then(response => response).catch(error => error);
    if (res && res.status) {
      this.setState({ version: res.version });
      return res.version
    }
    this.setState({ version: "" });
    return null
  }
  useDarkMode = () => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.setState({ isDarkMode: mediaQuery.matches });
    const handler = (event) => {
      this.setState({ isDarkMode: event.matches });
    };
    mediaQuery.addListener(handler);
    return () => mediaQuery.removeListener(handler);  // This is a cleanup function. We'll use it in componentWillUnmount.
  }
  handleSideMenuCloseOnClick  = () => {
    this.setState({ sideMenuActive: false });
    document.body.style.overflow = 'unset';
  }
  handleSideMenuShowOnClick = () => {
    this.setState({ sideMenuActive: true });
    document.body.style.overflow = 'hidden';
  }


  render() {
    const childProps = {
      version: this.version,
      getVersion: this.getVersion,
      useDarkMode: this.useDarkMode
    }
     return (
      <>
        {this.state.sideMenuActive &&
          <>
            <div className="mob-nav-bar">
              <div className="closeHead"><MdOutlineClose className="closex" onClick={this.handleSideMenuCloseOnClick} /></div>
              <ul className="mob-nav-links">
                <li className="nav-item-wrap">
                  <Link to="/company" className="nav-item flex align-center link w-inline-block" onClick={this.handleSideMenuCloseOnClick}>
                    <div className="mr-4">Company</div>
                  </Link>
                </li>
                <li className="nav-item-wrap">
                  <Link to="/careers" className="nav-item flex align-center link w-inline-block" onClick={this.handleSideMenuCloseOnClick}>
                    <div className="mr-4">Careers</div>
                  </Link>
                </li>
                <li className="nav-item-wrap">
                  <Link to="/investors" className="nav-item flex align-center link w-inline-block" onClick={this.handleSideMenuCloseOnClick}>
                    <div className="mr-4">Investors</div>
                  </Link>
                </li>
                <li className="nav-item-wrap">
                  <Link to="/connect" className="nav-item flex align-center link w-inline-block" onClick={this.handleSideMenuCloseOnClick}>
                    <div className="mr-4">Connect</div>
                  </Link>
                </li>
                <li className="nav-item-wrap">
                  <div className="lang link"  onClick={this.handleSideMenuCloseOnClick}><FaGlobeAmericas className="icon"/>&nbsp;en</div>
                </li>
              </ul>
            </div>
            <div className="mob-nav-bar-backd" onClick={this.handleSideMenuCloseOnClick}></div>
          </>
        }
        <div className="App">
          <Container fluid className={ "nav-bar" + (this.state.pageYOffset < 400 ? " noopac" : "")}>
            {this.props.location.pathname === "/" ?
              <Link to="/" className="logomain"><img alt="Geedop" src={logohw}/></Link>
            :
              <Link to="/" className="logomain"><img alt="Geedop" src={logohg}/></Link>
            }
            <ul className="nav-links">
              <li className="nav-item-wrap">
                <Link to="/company" className="nav-item flex align-center link w-inline-block">
                  <div className="mr-4">Company</div>
                </Link>
              </li>
              <li className="nav-item-wrap">
                <Link to="/careers" className="nav-item flex align-center link w-inline-block">
                  <div className="mr-4">Careers</div>
                </Link>
              </li>
              <li className="nav-item-wrap">
                <Link to="/investors" className="nav-item flex align-center link w-inline-block">
                  <div className="mr-4">Investors</div>
                </Link>
              </li>
              <li className="nav-item-wrap">
                <Link to="/connect" className="nav-item flex align-center link w-inline-block">
                  <div className="mr-4">Connect</div>
                </Link>
              </li>
            </ul>
            <div className="nav-right">
              <div className="lang link"><FaGlobeAmericas className="icon"/>&nbsp;en</div>
            </div>
            <div className="mob-nav-right">
              <Button className="button sm outline" onClick={this.handleSideMenuShowOnClick}>Menu</Button>
            </div>
          </Container>
          <div className="main">
            <ScrollRestoration />
            <Routes childProps={childProps} />
          </div>
          <div className="footer" >
            <Footer location={this.props.location} version={this.state.version} />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(App);

