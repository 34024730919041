const dev = {
  api: {
    REGION: "us-east-1",
    URL: "https://3llp9lrika.execute-api.us-east-1.amazonaws.com/dev/"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_iP8eZX3Un",
    APP_CLIENT_ID: "uv3ffumns509epgsr6jd49f0s",
    IDENTITY_POOL_ID: "us-east-1:9c652dad-41ca-4aa5-b09f-8dba008fb394"
  },
};

const prod = {
  api: {
    REGION: "us-east-1",
    URL: "https://bbzd61qjj6.execute-api.us-east-1.amazonaws.com/prod/"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_yU25x1e9J",
    APP_CLIENT_ID: "uefkkrjoekeqa5bl14dinu6b0",
    IDENTITY_POOL_ID: "us-east-1:132280f1-a441-4857-b4f0-393617a2ade1"
  },
};

const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

const configEx = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};

export default configEx;