import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './Footer.css'
import geedopLogo from '../img/logos/logo-bot-blugra.png';
import geedopLogoD from '../img/logos/logo-bot-blugda.png';

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      year: '2022'
    }
  }
  componentDidMount = () => {
    var year = new Date().getFullYear()
    this.setState({ year })
  }

  render() {
    return (
      <div fluid="true" className="Footer">
        <Container>
          <Row>
            <Col sm={8} className="left">
              <ul className="list-inline">
                  <li className="list-inline-item"><span>&copy; {this.state.year} Geedop, LLC</span></li>
                  <li className="list-inline-item">|</li>
                  <li className="list-inline-item"><Link to="/company">Company</Link></li>
                  <li className="list-inline-item"><Link to="/careers">Careers</Link></li>
                  <li className="list-inline-item"><Link to="/investors">Investors</Link></li>
                  <li className="list-inline-item"><Link to="/connect">Connect</Link></li>
                  <li className="list-inline-item">|</li>
                  <li className="list-inline-item"><Link to="/terms">Terms</Link></li>
                  <li className="list-inline-item"><Link to="/privacy">Privacy</Link></li>
                  <li className="list-inline-item">|</li>
                  <li className="list-inline-item">{this.props.version ? "v" + this.props.version : ""}</li>
              </ul>
            </Col>
            <Col sm={4} className="right">
              <ul className="list-inline">
                {this.props.location.pathname === "/" || this.props.location.pathname.includes('company') ?
                  <li className="list-inline-item"><Link to="/"><img className="geedop-logo" src={geedopLogo} alt="" /></Link></li>
                :
                  <li className="list-inline-item"><Link to="/"><img className="geedop-logo" src={geedopLogoD} alt="" /></Link></li>
                }
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Footer;
