import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import Amplify from "aws-amplify";
import config from "./config";

var domain = 'localhost'
var secure = false

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  domain = 'localhost'
  secure = false
} else {
  if (process.env.REACT_APP_STAGE === 'prod') {
    domain = 'dev.geedop.com'
    secure = true
  } else  {
    domain = 'www.geedop.com'
    secure = true
  }
}

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    // OPTIONAL - Configuration for cookie storage
    cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: domain,
    // OPTIONAL - Cookie path
        path: '/',
    // OPTIONAL - Cookie expiration in days
        expires: 1,
    // OPTIONAL - Cookie secure flag
        secure: secure,
        sameSite: 'lax'
    },
  },
  API: {
    endpoints: [
      {
        name: "data",
        endpoint: config.api.URL,
        region: config.api.REGION
      },
    ]
  }
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();