import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "./components/AsyncComponent";
import AppliedRoute from "./components/AppliedRoute"

const AsyncHome         = asyncComponent(() => import(/* webpackChunkName: "home" */"./containers/Home"));
const AsyncPrivacy      = asyncComponent(() => import(/* webpackChunkName: "privacy" */"./containers/Privacy"))
const AsyncTerms        = asyncComponent(() => import(/* webpackChunkName: "terms" */"./containers/Terms"))
const AsyncConnect      = asyncComponent(() => import(/* webpackChunkName: "connect" */"./containers/Connect"))
const AsyncCareers      = asyncComponent(() => import(/* webpackChunkName: "careers" */"./containers/Careers"))
const AsyncCareersJob   = asyncComponent(() => import(/* webpackChunkName: "careersjob" */"./containers/CareersJob"))
const AsyncInvestors    = asyncComponent(() => import(/* webpackChunkName: "investors" */"./containers/Investors"))
const AsyncCompany      = asyncComponent(() => import(/* webpackChunkName: "company" */"./containers/Company"));
const AsyncQuickLaunch  = asyncComponent(() => import(/* webpackChunkName: "quicklaunch" */"./containers/QuickLaunch"));
const AsyncNotFound     = asyncComponent(() => import(/* webpackChunkName: "notfound" */"./containers/NotFound"));

const Routes = ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={AsyncHome} props={childProps} />
    <AppliedRoute path="/privacy" exact component={AsyncPrivacy} props={childProps} />
    <AppliedRoute path="/terms" exact component={AsyncTerms} props={childProps} />
    <AppliedRoute path="/connect" exact component={AsyncConnect} props={childProps} />
    <AppliedRoute path="/careers" exact component={AsyncCareers} props={childProps} />
    <AppliedRoute path="/careers/job/:jobid?" exact component={AsyncCareersJob} props={childProps} />
    <AppliedRoute path="/investors" exact component={AsyncInvestors} props={childProps} />
    <AppliedRoute path="/company/:unit?" component={AsyncCompany} props={childProps} />
    <AppliedRoute path="/quicklaunch" exact component={AsyncQuickLaunch} props={childProps} />
    <Route component={AsyncNotFound} />
  </Switch>;

export default Routes;